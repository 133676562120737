import { Pipe, PipeTransform } from '@angular/core'
import { ChartDataSource } from '../types/enums'

@Pipe({
  name: 'datasourceNamePipe',
})
export class DatasourceNamePipe implements PipeTransform {
  amount: any

  transform(value: ChartDataSource | string): string {
    var stringValue: string =
      (typeof value).toString() !== 'number'
        ? (value as string)
        : ChartDataSource[value as keyof typeof ChartDataSource].toString()

    var enumValue: ChartDataSource =
      (typeof value).toString() !== 'number'
        ? ChartDataSource[value as keyof typeof ChartDataSource]
        : (value as ChartDataSource)

    switch (enumValue) {
      case ChartDataSource.ForecastedTasks:
        return 'Forecasted Tasks'
      case ChartDataSource.ForecastedTasksInProgress:
        return 'Forecasted Tasks - In Progress'
      case ChartDataSource.TaskDurations:
        return 'Task Durations'
      default:
        return stringValue
    }
  }
}
