import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from 'src/app/shared/services/api/auth.api.service';
import { UserService } from 'src/app/shared/services/api/user.api.service';
import { AppArea, AppStateService } from 'src/app/shared/services/app-state.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LogService } from 'src/app/shared/services/log.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CompanyDto } from 'src/app/types/dto/company.dto';
import { UserDto } from 'src/app/types/dto/user.dto';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  currentComp?: CompanyDto;
  currentUser?: UserDto;
  currentUserEmail?: string;
  appVersion?: string;

  userCompanies?: CompanyDto[];
  loading = true;
  multipleCompanies = false;

  constructor(
    private auth: AuthService,
    private authApi: AuthApiService,
    private userApi: UserService,
    private appState: AppStateService,
    private logger: LogService,
    private notiService: NotificationService,
    private router: Router) { }

  ngOnInit() {
    this.currentComp = this.auth.getCurrentCompany();

    this.appState.setAreaSelected(AppArea.home);

    this.appVersion = this.appState.appVersion;

    this.currentUserEmail = this.auth.getUserEmail();
    this.currentUser = this.auth.getCurrentUser();

    this.getCompanies();
  }

  compareComps(comp1: CompanyDto, comp2: CompanyDto) {
    if (!comp1 || !comp2) {
      return false;
    } else {
      return comp1.id === comp2.id;
    }
  }

  compSelected() {
    if (!this.currentComp) {
      this.logger.log('home', 'compSelected', 'no company in compSelected')
      throw Error('no company in compSelected')
    }

    this.loading = true;
    this.auth.setCurrentCompany(this.currentComp);

    // get the user master - only have all user info once a company is selected
    this.userApi.getUser(this.currentComp.userId).subscribe(
      currUser => {
        if (!this.currentComp) {
          this.logger.log('home', 'compSelected', 'no company in compSelected subscription')
          throw Error('no company in compSelected subscription')
        }
        this.currentUser = currUser;
        this.auth.setCurrentUser(this.currentUser);
        this.auth.setCurrentCompany(this.currentComp); // run again to get the perms

        this.logger.loginLog(
          currUser.email, true, 'Company ' + this.currentComp.id.toString());

        this.router.navigateByUrl('dashboard');
      },
      err => {
        this.logger.log('home', 'compSelected()', err, 'Error getting user', true);
        this.currentUser = undefined;
        this.notiService.handleError(err);
        this.loading = false;
      });
  }

  signOut() {
    this.loading = true;
    this.auth.signOut();
  }

  private getCompanies() {
    if (!this.currentUserEmail) {
      this.logger.log('home', 'getCompanies()', null, 'no user email', true);
      this.logger.writeLog('no user email in home::getCompanies()', '')
      this.auth.signOut()
      return
    }

    this.authApi.getAuthorisedCompanies(this.currentUserEmail)
      .subscribe(companies => {
        this.userCompanies = companies;
        this.populateCompanies();
      },
      err => {
        this.logger.log('home', 'populateCompanies()', err, 'Error getting companies', true);
        this.currentUser = undefined;
        this.notiService.handleError(err);
        this.loading = false;
      });
  }

  private populateCompanies() {
    this.loading = false;

    if (this.userCompanies?.length === 1) {
      this.multipleCompanies = false;
      this.currentComp = this.userCompanies[0];

      if (!this.auth.isSignedIn) {
        this.compSelected();
      }
    } else {
      this.multipleCompanies = true;
    }
  }
}
