import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatCardModule } from '@angular/material/card'
import { MatMenuModule } from '@angular/material/menu'
import { MatToolbarModule } from '@angular/material/toolbar'
import { DashCardComponent } from './dash-card/dash-card.component'
import { SharedModule } from 'src/app/shared/shared.module'
import { DashboardComponent } from './dashboard/dashboard.component'
import { DashboardsComponent } from './dashboards.component'
import { AddEditDashboardComponent } from './add-edit-dashboard/add-edit-dashboard.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatInputModule } from '@angular/material/input'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { EditCardComponent } from './edit-card/edit-card.component'
import {
  DxButtonModule,
  DxChartModule,
  DxColorBoxModule,
  DxDataGridModule,
  DxFilterBuilderModule,
  DxSelectBoxModule,
} from 'devextreme-angular'
import { ChartComponent } from './chart/chart.component'
import { MatNativeDateModule } from '@angular/material/core'
import { MatRadioModule } from '@angular/material/radio'
import { PointInfoComponent } from './chart/point-info/point-info.component'
import { PointDialogComponent } from './chart/point-dialog/point-dialog.component'
import { CloneCardComponent } from './clone-card/clone-card.component'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,

    FormsModule,
    ReactiveFormsModule,

    // MATERIAL
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatGridListModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRadioModule,
    MatToolbarModule,

    // DX
    DxButtonModule,
    DxChartModule,
    DxColorBoxModule,
    DxDataGridModule,
    DxFilterBuilderModule,
    DxSelectBoxModule,
  ],
  declarations: [
    AddEditDashboardComponent,
    ChartComponent,
    CloneCardComponent,
    DashboardComponent,
    DashboardsComponent,
    DashCardComponent,
    EditCardComponent,
    PointDialogComponent,
    PointInfoComponent,
  ],
})
export class DashboardModule {}
