<div class="modal-header">
  {{ chartConfig?.cardTitle }}
  <span *ngIf="!smallScreen">
    <span class="m-l-10 m-r-12 vertical-gradient"></span>
    Chart Point Info
  </span>
  <mat-icon class="float-r pointer" mat-dialog-close>close</mat-icon>
 </div>
 
 <app-point-info
  [chartItem]="chartItem"
  [chartConfig]="chartConfig"
  [valueText]="valueText"
  [toolTipMode]="false"></app-point-info>

  <mat-card *ngIf="chartItem?.jobIds?.length" class="m-t-20">
    <mat-card-header class="bold">Job Details</mat-card-header>
    <dx-data-grid [dataSource]="jobs"
      [height]="chartHeight"
      [allowColumnResizing]="true"
      columnResizingMode="nextColumn"
      [noDataText]="!loading ? 'No Data' : 'Loading...'">
      <dxo-export [enabled]="true"></dxo-export>
      <dxi-column dataField="jobNumber" width="150" cellTemplate="jobCellTemplate"></dxi-column>
      <div *dxTemplate="let data of 'jobCellTemplate'" class="job-cell">
        <div (click)="openJob(data.value)" class="open-new">
          {{ data.value }}
          <mat-icon class="open-new-icon">open_in_new</mat-icon>
        </div>
      </div>
      <dxi-column dataField="contractName" minWidth="120"></dxi-column>
      <dxi-column dataField="jobAddress" minWidth="120"></dxi-column>
      <dxi-column dataField="contractAddress" minWidth="120"></dxi-column>
    </dx-data-grid>
</mat-card>
