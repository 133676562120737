import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-confirm',
  template: `
    <div class="modal-header" [ngClass]="{ danger: danger }">{{ title }}</div>
    <mat-dialog-content class="p-b-10" [innerHTML]="message"></mat-dialog-content>
    <div class="modal-footer">
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button [mat-dialog-close]="true" [ngClass]="{ danger: danger }">
        {{ yesText }}
      </button>
    </div>
  `,
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
  message: string
  title: string
  danger = false
  yesText: string

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.message = data.message ?? ''
    this.title = data.title ?? 'Confirm'
    this.danger = data.danger
    this.yesText = data.yesText ?? 'Yes'
  }
}
