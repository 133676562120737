export const APP_NAME = 'TruthEngineHub'
export const APP_ID = 7

export const MAJOR_VERSION = '1.3'
export const PATCH_VERSION = '.13' // not stored in DB or checked in refresh guard

export const AVERAGING_DECIMAL_PLACES_ROUNDING = 2

/// B2C / MSAL ///
export const B2C_AUTHORITY =
  'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-prod'
export const B2C_AUTHORITY_DEV =
  'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-dev'
export const B2C_AUTHORITY_DOMAIN = 'truthengine.b2clogin.com'

export const MAX_DASHBOARD_NAME_LEN = 35
