import { EventEmitter, Injectable } from '@angular/core';
import { DashboardApiService } from 'src/app/shared/services/api/dashboard.api.service';
import { LogService } from 'src/app/shared/services/log.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DashboardDto, DashBoardLite } from 'src/app/types/dto/dashboard.types.dto';
import { DashboardService } from './dashboard/dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardsService {

  dashboards: DashBoardLite[] | undefined;
  selectedDashboardId: number | undefined;
  selectedDashboardName: string | undefined;
  defaultDashboardId: number | undefined;

  dashboardsUpdated = new EventEmitter<boolean>();

  get currentDashIsDefault() { return this.defaultDashboardId == this.selectedDashboardId }
  get dashboardsExist() { return this.dashboards != undefined && this.dashboards.length > 0 }

  constructor(
    private notiService: NotificationService,
    private logger: LogService,
    private dashboardApi: DashboardApiService,
    private dashboardService: DashboardService)
  { }

  initDashboards() {
    this.dashboardApi.getDashboards()
      .subscribe({
        next: (boards) => {
          this.dashboards = boards.dashBoards;
          this.defaultDashboardId = boards.defaultDashboardForUserId;

          this.setSelectedDashboard(
            this.defaultDashboardId
            ? this.defaultDashboardId
            : (boards.dashBoards && boards.dashBoards.length > 0)
              ? boards.dashBoards[0].id
              : undefined);

          this.dashboardsUpdated.emit(true);
        },
        error: (err) => {
          this.notiService.handleError(err);
          this.dashboardsUpdated.emit(false);
          this.logger.log('dashboards.service', 'initDashboards', err);
        }
      });
  }

  dashIsDefault(dashId: number) {
    return this.defaultDashboardId == dashId;
  }

  setDefaultDashboard(dashId: number | undefined) {
    this.defaultDashboardId = dashId;
  }

  updateDashboardListChangedName(updatedDashId: number, newName: string) {
    if (this.dashboards) {
      const dash = this.dashboards
        .filter(d => d.id == updatedDashId)
        [0];

      dash.name = newName;
    }

    if (this.selectedDashboardId === updatedDashId) {
      this.selectedDashboardName = newName;
    }
  }

  addAndSelectDashboard(board: DashboardDto) {
    if (this.dashboards) {
      this.dashboards?.push({id: board.id, name: board.name});
      this.dashboards.sort((a, b) => a.name.localeCompare(b.name));

      this.setSelectedDashboard(board.id);
      this.dashboardsUpdated.emit(true);
    }
  }

  removeDashboard(idToDelete: number) {
    if (this.dashboards) {
      const toDelete = this.dashboards
        .filter(d => d.id == idToDelete)
        [0];
      const indexToDelete = this.dashboards.indexOf(toDelete);
      this.dashboards.splice(indexToDelete, 1);

      if (this.defaultDashboardId === idToDelete) {
        this.defaultDashboardId = undefined;
      }

      const dashToSelect = this.defaultDashboardId ??
        this.dashboards.length > 0 
        ? this.dashboards[0].id
        : undefined;
      
      this.setSelectedDashboard(dashToSelect);
      this.dashboardService.initDashboard(dashToSelect);
      this.dashboardsUpdated.emit(true);
    }
  }

  setSelectedDashboard(dashId: number | undefined) {
    this.selectedDashboardId = dashId;
    this.selectedDashboardName = dashId
      ? this.dashboards
        ?.filter(d => d.id == dashId)
        [0]
        .name
      : undefined;
  }
}
