<div class="modal-header">
  Edit Chart
 </div>

 <mat-dialog-content *ngIf="form" [formGroup]="form" class="mat-typography">
  <mat-form-field appearance="fill" class="full-width"
    [ngClass]="{'err-clr' : input.value && input.value.length > MAX_NAME_LEN}">
    <mat-label>Name</mat-label>
    <input #input formControlName="name" matInput cdkFocusInitial>
    <mat-hint align="end" [ngClass]="{'err-clr' : input.value && input.value.length > MAX_NAME_LEN}">
      {{input.value.length || 0}}/{{MAX_NAME_LEN}}
    </mat-hint>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width m-t-10">
    <mat-label>Datasource</mat-label>
    <mat-select formControlName="datasource">
      <mat-option *ngFor="let source of datasourceOptions" [value]="source">
        {{ source | datasourceNamePipe }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="dataSourceFilterFields">
    <div [ngClass]="{'section' : isTaskDurationSource}">
      <ng-container *ngIf="isTaskSource">
        <dx-select-box *ngIf="taskTypes" [ngClass]="{'m-b-20' : !isTaskDurationSource}"
          [dataSource]="taskTypes" formControlName="taskType"
          [label]="isTaskDurationSource
            ? 'Task 1 Type'
            : 'Task Type'"
          [searchEnabled]="true"
          [placeholder]="isTaskDurationSource
            ? 'Select a Task type that occurs first'
            : 'Select a Task type'"
          [grouped]="true"
          displayExpr="taskTitle" valueExpr="id">
        </dx-select-box>

        <div *ngIf="isTaskSource && !taskTypes && !tasksError" class="task-spinner"
          [ngClass]="{'m-b-20' : !isTaskDurationSource}">
          <app-spinner [inline]="true" heightPx="48"></app-spinner>
        </div>
        <div *ngIf="tasksError" class="error-box-sml">
          There was an error loading the tasks. Please try again in a moment.
        </div>
      </ng-container>
      
      <ng-container *ngIf="isTaskDurationSource">
        <mat-form-field appearance="fill" class="full-width m-t-20">
          <mat-label>Task 1 date field</mat-label>
          <mat-select formControlName="task1DateField">
            <mat-option *ngFor="let df of dateFilterFieldOptions" [value]="df.dataField">
              {{df.displayName}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <dx-select-box *ngIf="taskTypes"
          [dataSource]="taskTypes" formControlName="taskType2"
          label="Task 2 Type"
          [searchEnabled]="true" placeholder="Select a Task type that occurs second"
          [grouped]="true"
          displayExpr="taskTitle" valueExpr="id">
        </dx-select-box>

        <div *ngIf="!taskTypes && !tasksError" class="task-spinner">
          <app-spinner [inline]="true" heightPx="48"></app-spinner>
        </div>
        <div *ngIf="tasksError" class="error-box-sml">
          There was an error loading the tasks. Please try again in a moment.
        </div>

        <mat-form-field appearance="fill" class="full-width no-padding-bottom m-t-20">
          <mat-label>Task 2 date Field</mat-label>
          <mat-select formControlName="task2DateField">
            <mat-option *ngFor="let df of dateFilterFieldOptions" [value]="df.dataField">
              {{df.displayName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>

    <div class="section">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Value Field (number attribute to graph on Y axis)</mat-label>
        <mat-select formControlName="valueField" [disabled]="valueFieldOptions.length === 1">
          <mat-option *ngFor="let vf of valueFieldOptions" [value]="vf.dataField">
            {{vf.displayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="isTaskDurationSource" appearance="fill" class="full-width">
        <mat-label>Duration Aggregation</mat-label>
        <mat-select formControlName="taskDurationOption">
          <mat-option *ngFor="let option of taskDurationOptions" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="usingCustomValueField" appearance="fill" class="full-width">
        <mat-label>Value Aggregation Type</mat-label>
        <mat-select formControlName="valueAggregation">
          <mat-option *ngFor="let aggregation of aggregationOptions" [value]="aggregation">
            {{aggregation}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="cumulativeAggregation && !hideStartingValue" appearance="fill" class="full-width">
        <mat-label>Starting Value (ignored if graphing multiple series)</mat-label>
        <input formControlName="startingValue" type="number" step="any" matInput>
      </mat-form-field>

      <mat-form-field *ngIf="cumulativeAggregation" appearance="fill" class="full-width">
        <mat-label>Expected % cancellation</mat-label>
        <input formControlName="percentCancellation" type="number" step="1" max="100" matInput>
        <span matSuffix>%&nbsp;</span>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Argument Field (attribute to group-by on X axis)</mat-label>
        <mat-select formControlName="argumentField" [disabled]="argFieldOptions.length === 1">
          <mat-option *ngFor="let af of argFieldOptions" [value]="af.dataField">
            {{af.displayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="argFieldIsDateType" appearance="fill"
        class="full-width">
        <mat-label>Argument Date Grouping</mat-label>
        <mat-select formControlName="grouping">
          <mat-option *ngFor="let group of groupOptions" [value]="group">
            {{group}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="showGroupBySeries" appearance="fill"
        class="full-width no-padding-bottom">
        <mat-label>Graph separate series split by</mat-label>
        <mat-select formControlName="splitByField">
          <mat-option *ngFor="let series of seriesOptions" [value]="series">
            {{series.displayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="!isTaskDurationSource" class="m-t-20 p-l-5 p-r-5">
        <span class="m-r-10">Show filtered categories only</span>
        <mat-checkbox formControlName="showFilteredCategoriesOnly">
        </mat-checkbox>
        <mat-icon id="filteredCategoriesId" class="filtered-categories-help-icon">info_outline</mat-icon>
        <dx-tooltip
          target="#filteredCategoriesId"
          showEvent="mouseenter"
          hideEvent="mouseleave"
          position="top"
        >
          <p><b>Only show categories/options that occur in the filtered data.</b></p>
          <p>This applies to non-date x-axis groupings and separate-series splitting categories, <br>
             and only applies if a date filter or custom filter is applied.</p>
          <p>E.G. if grouping by 'House Type', splitting by 'Division' and filtering to the last month, <br>
             only the House Types that occur in the last month will be shown as x-axis groupings/categories, <br>
              and only Divisions that occur in the last month will be shown as series in the legend.</p>
          <p>DISABLED BENEFIT<br>
             Accurately shows all possible options i.e. all Divisions in the company<br>
             Colours will be consistent across charts with the same dataset</p>
          <p>ENABLED BENEFIT<br>
             Reduces noise (empty categories) if filtering by a narrow time range <br>
             or explicitly filtering out categories</p>
        </dx-tooltip>
      </div>
    </div>

    <div class="section">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Date Filter Field</mat-label>
        <mat-select formControlName="dateFilterField">
          <mat-option *ngFor="let df of dateFilterFieldOptions" [value]="df.dataField">
            {{df.displayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="flex justify-between">
        <mat-form-field appearance="fill" class="half-field no-padding-bottom">
          <mat-label>Date Range</mat-label>
          <mat-select formControlName="rangeType">
            <mat-option *ngFor="let range of rangeOptions" [value]="range"
              [disabled]="rangeInvalidForGrouping(range)"
              [matTooltip]="rangeInvalidForGrouping(range)
                ? 'The current Grouping does not allow this option'
                : ''">
              {{range}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="form.controls.rangeType.value !== 'All Time'" appearance="fill"
          class="half-field no-padding-bottom">
          <mat-label>
            {{dynamicDateRange ? 'Example (current) Range' : 'Custom Date Range'}}
          </mat-label>
          <mat-date-range-input [rangePicker]="picker"
            [disabled]="form.controls.rangeType.value !== 'Custom'">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div *ngIf="rangeNotAlignToGrouping" class="warn-box-sml m-t-10">
        The custom range does not align to the selected Grouping.
        <br> Values in the first and/or last periods may be unexpected.
      </div>
      <!-- <div *ngIf="dynamicDateRange" class="info-box-sml m-b-5">
        NOTE: The range will be adjusted to fit the current grouping.
        <br> The start date will be moved forward to align to the start of the {{currentGroupingPeriod()}}
      </div> -->
    </div>

    <div class="section m-b-20">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Chart Type</mat-label>
        <mat-select formControlName="chartType">
          <mat-option *ngFor="let chart of chartOptions" [value]="chart">
            {{chart}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <div class="dx-field m-b-20" *ngIf="!hideChartColorSelection">
        <div class="dx-field-label color-label" style="width: 25%">Chart Colour</div>
        <div class="dx-field-value" style="width: 75%">
          <dx-color-box [(value)]="chartColour"></dx-color-box>
        </div>
      </div>
  
      <mat-form-field appearance="fill" class="half-field no-padding-bottom">
        <mat-label>Target Value</mat-label>
        <input #input formControlName="targetValue" type="number" min="0" matInput>
      </mat-form-field>
    </div>

    <div *ngIf="dataSourceFilterFields?.length" class="section">
      <div class="filter-label">
        Filters
      </div>
      <dx-filter-builder [disabled]="!form.controls.datasource.value"
        [fields]="dataSourceFilterFields"
        [(value)]="filter" class="filter">
      </dx-filter-builder>
    </div>
  </ng-container>
</mat-dialog-content>

<div class="modal-footer">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-stroked-button color="warn"
    (click)="delete()" [disabled]="loading">
    Delete
  </button>
  <button mat-stroked-button color="primary"
    (click)="save()" [disabled]="form?.invalid || loading">
    Save
  </button>
</div>