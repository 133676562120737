// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Routes, RouterModule, InitialNavigation } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { DashboardsComponent } from './pages/dashboard/dashboards.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './shared/components/login/login.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from './pages/home/home.component';
import { RefreshGuard } from './shared/services/refresh-guard.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TrainingModule } from './pages/training/training.module';
import { TrainingComponent } from './pages/training/training.component';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardsComponent,
    canActivate: [MsalGuard, RefreshGuard]
  },
  {
    path: 'training',
    component: TrainingComponent,
    canActivate: [MsalGuard, RefreshGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [RefreshGuard]
  },
  {
    path: 'auth', // msal callback
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // useHash: true,
      // enableTracing: true,
      // Don't perform initial navigation in iframes or popups
      initialNavigation: (!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()) ? 'enabled' as InitialNavigation : undefined
    }),
    DashboardModule,
    TrainingModule],
  providers: [MsalGuard],
  exports: [RouterModule],
  declarations: []
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class AppRoutingModule { }
