<div class="login-wrapper">

  <img src="../assets/TElogo.png" />

  <div class="flex justify-center m-t-20">
    <button *ngIf="isHttps && showLoginButton" mat-flat-button color="primary" (click)="loginClicked()">
      Login
    </button>
    <div><app-spinner *ngIf="!showLoginButton" [inline]="true"></app-spinner>
    </div>

    <div *ngIf="!isHttps" class="warn-box">
      For security reasons you must use HTTPS when accessing Truth Engine.
      <br>Please use the button below to go to the secure https site.
      <br><br>Update your bookmark to avoid seeing this message again.

      <button mat-stroked-button color="primary" class="m-t-30 block auto-margin" (click)="goToHttps()">
        Proceed to secure site
      </button>
    </div>

  </div>

  <br>
  <br>
  <button *ngIf="showRefreshButton" class="btn btn-sm ms-2" (click)="refresh()">
    Refresh
  </button>

  <br>
  <br>
  <button class="btn btn-sm ms-2" (click)="clearCache()">
    to clear memory click here
  </button>
