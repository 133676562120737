import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { DatasourceNamePipe } from 'src/app/shared/data-source-name.pipe'
import { ScreenService } from 'src/app/shared/services'
import { DatasourceConfigService } from 'src/app/shared/services/datasource-config.service'
import { DateUtilsService } from 'src/app/shared/services/date-utils.service'
import { LogService } from 'src/app/shared/services/log.service'
import { SeriesValue, UtilsService } from 'src/app/shared/services/utils.service'
import { ChartConfig, ChartValue, GenericChartItem } from 'src/app/types/chart.model'
import { DEFAULT_VALUE_FIELD } from 'src/app/types/constants'
import {
  ChartDataSource,
  ChartType,
  DateAxisType,
  DateRangeType,
  ValueAggregationType,
} from 'src/app/types/enums'
import { DISABLED_SEPARATE_SERIES } from '../../edit-card/edit-card.component'

export const MANY_SERIES_CUTOFF = 6

@Component({
  selector: 'app-point-info',
  templateUrl: './point-info.component.html',
  styleUrls: ['./point-info.component.scss'],
})
export class PointInfoComponent implements OnChanges {
  @Input() chartConfig?: ChartConfig

  @Input() chartItem?: GenericChartItem

  @Input() valueText?: string

  @Input() toolTipMode = true

  dynamicSeriesForItem: SeriesValue[] = []
  numItems: number = 0
  multipleSeries = false
  seriesGroupByName = ''
  manySeries = false

  chartItemName: 'bar' | 'point' = 'bar'
  argumentDisplayName = ''
  valueDisplayName = ''
  dateFilterDisplayName = ''
  toolTipPrefix = ''
  toolTipValueHeader = 'Number of items'
  valueAggregationIsCount = false
  showFilteredCategoriesWarning = false

  datasourceEnum = ChartDataSource

  constructor(
    public datasourceNamePipe: DatasourceNamePipe,
    public screen: ScreenService,
    private datasourceConfigService: DatasourceConfigService,
    private logger: LogService,
    private utils: UtilsService,
    private dateUtils: DateUtilsService
  ) {}

  ngOnChanges(_: SimpleChanges) {
    if (this.chartConfig) {
      this.getDisplayNames()

      this.valueAggregationIsCount =
        !this.chartConfig?.valueField || this.chartConfig.valueField === DEFAULT_VALUE_FIELD

      this.chartItemName = this.chartConfig?.chartTypeId == ChartType.Bar ? 'bar' : 'point'

      this.multipleSeries = this.utils.isMultipleSeries(this.chartConfig)

      if (this.chartItem) {
        this.numItems = this.chartItem.numItems

        if (this.multipleSeries) {
          const series = this.utils.getDynamicSeriesFromChartItem(this.chartItem, false)
          this.dynamicSeriesForItem = series
          if (series.length > MANY_SERIES_CUTOFF) this.manySeries = true

          // override with total value as each series is broken down separately underneath
          this.valueText = this.chartItem.value.toString()
        }

        var hasFilters =
          this.chartConfig.dateRangeTypeId !== DateRangeType['All Time'] ||
          !!this.chartConfig.filters
        this.showFilteredCategoriesWarning =
          this.chartConfig.showFilteredCategoriesOnly &&
          hasFilters &&
          !(this.manySeries && this.toolTipMode)

        if (this.chartConfig.dataSourceId && this.chartConfig.splitByField)
          this.seriesGroupByName =
            this.datasourceConfigService
              .getDatasourceSeriesGroupFields(this.chartConfig?.dataSourceId)
              .filter((f) => f.dataField == this.chartConfig!.splitByField)[0]?.displayName ??
            this.chartConfig.splitByField
      }
    }
  }

  getToolTipArgument = (point: GenericChartItem) => {
    if (!this.chartConfig?.argumentFieldIsDate) {
      return point.groupField
    }

    switch (this.chartConfig?.dateAxisTypeId) {
      case DateAxisType.Daily:
        return this.dateUtils.formatDateStandard(point.groupField)
      case DateAxisType.Yearly:
        return this.dateUtils.getDateYearString(point.groupField)
      case DateAxisType.Monthly:
        return this.dateUtils.getDateMonthString(point.groupField)
      case DateAxisType.Weekly:
        return this.dateUtils.formatDateStandard(point.groupField)
      default:
        this.logger.log(
          'point-info',
          'getToolTipArgument',
          null,
          'unhandled date axis grouping in chart'
        )
        throw new Error('unhandled date axis grouping in chart')
    }
  }

  getDateRangeText = (): string => {
    if (!this.chartConfig || this.chartConfig.dateRangeTypeId == null) {
      this.logger.log(
        'point-info',
        'getDateRangeText',
        null,
        'no config / dateRangeTypeId /dateFrom / dateTo in getDateRangeText'
      )
      throw Error('no config / dateRangeTypeId /dateFrom / dateTo in getDateRangeText')
    }

    return this.dateUtils.getDateRangeHTMLWithDates(
      this.chartConfig.dateRangeTypeId,
      this.chartConfig.dateFrom,
      this.chartConfig.dateTo
    )
  }

  getSeriesNumItemsText(series: SeriesValue) {
    const numItems = this.chartItem?.seriesNumItems[series.name]

    if (
      numItems == null ||
      // num items will be redundant (same as the value) if using default value aggregation
      this.valueAggregationIsCount ||
      // num items for current period is misleading for cumulative values
      this.chartConfig?.valueAggregationTypeId === ValueAggregationType.Cumulative
    )
      return ''

    return ` (${numItems} item${numItems > 1 ? 's' : ''})`
  }

  private getDisplayNames() {
    if (!this.chartConfig) {
      this.logger.log('point-info', 'getDisplayNames', null, 'no chart config in chart component')
      throw Error('no chart config in chart component')
    }

    const datasource = this.chartConfig.dataSourceId

    this.argumentDisplayName = this.datasourceConfigService.getArgFieldDisplayName(
      datasource,
      this.chartConfig.argumentField
    )
    this.valueDisplayName = this.datasourceConfigService.getValueFieldDisplayName(
      datasource,
      this.chartConfig.valueField
    )
    this.dateFilterDisplayName = this.datasourceConfigService.getDateFilterFieldDisplayName(
      datasource,
      this.chartConfig.dateFilterField
    )

    if (!this.valueAggregationIsCount) {
      const prefix = this.utils.getDefaultValueAggregationPrefix(
        this.chartConfig.valueAggregationTypeId
      )
      const datasourceValueString = this.datasourceConfigService.getTooltipValueString(datasource)
      this.toolTipValueHeader = `${prefix} '${datasourceValueString ?? this.valueDisplayName}'`
    }

    this.toolTipPrefix = this.getToolTipPrefix(this.chartConfig)
  }

  private getToolTipPrefix(config: ChartConfig) {
    if (!this.chartConfig) {
      this.logger.log('point-info', 'getToolTipPrefix', null, 'no config in getToolTipPrefix')
      throw Error('no config in getToolTipPrefix')
    }

    if (!config.argumentFieldIsDate) {
      return this.argumentDisplayName
    }

    return this.dateUtils.getDateGroupingPeriodName(this.chartConfig.dateAxisTypeId)
  }
}
