import { Injectable } from '@angular/core'
import { JobAddress } from 'src/app/types/dto/job.dto'
import { ChartDataSource, ValueAggregationType } from 'src/app/types/enums'
import { LogService } from './log.service'
import {
  ChartConfig,
  ChartValue,
  GenericChartItem,
  KNOWN_CHART_ITEM_FIELDS,
} from 'src/app/types/chart.model'
import { DISABLED_SEPARATE_SERIES } from 'src/app/pages/dashboard/edit-card/edit-card.component'
import { SERIES_EMPTY_TEXT } from './chart-datasource-provider.service'
import { DatasourceConfigService } from './datasource-config.service'

export interface SeriesValue {
  name: string
  value: number
  numItems: number
}

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private datasourceConfig: DatasourceConfigService, private logger: LogService) {}

  isMultipleSeries(config: ChartConfig) {
    return !!config.splitByField && config.splitByField !== DISABLED_SEPARATE_SERIES
  }

  showFilteredCategoriesOnly(config: ChartConfig) {
    return (
      config.showFilteredCategoriesOnly ||
      // task-duration charts can't use this yet - always filter
      config.dataSourceId == ChartDataSource.TaskDurations
    )
  }

  createChartSeries(
    items: any[],
    config: ChartConfig,
    formatDateFunc: (d: any) => string
  ): [boolean, boolean, ChartValue[]] {
    let splittingByDateField = false
    let chartSeries: ChartValue[] = []

    const isMultipleSeries = this.isMultipleSeries(config)

    if (isMultipleSeries) {
      const uniqueValues = Array.from(
        new Set(
          items
            .map((i) => i[config.splitByField])
            .map((i) => {
              if (typeof i === 'boolean') return i.toString()
              else return this.checkForNullOrEmpty(i)
            })
        )
      )
      splittingByDateField = this.datasourceConfig.seriesGroupingIsDate(
        config.dataSourceId,
        config.splitByField
      )
      chartSeries = uniqueValues.map((v) => ({
        label: splittingByDateField ? formatDateFunc(v) : v.toString(),
        value: splittingByDateField ? formatDateFunc(v) : v,
      }))
    }

    chartSeries.sort((a, b) => (a.label > b.label ? 1 : -1))

    return [splittingByDateField, isMultipleSeries, chartSeries]
  }

  getThemePrimaryColour() {
    return window.getComputedStyle(document.documentElement).getPropertyValue('--primary')
  }

  isHttps() {
    return window.location.protocol.startsWith('https') || window.location.hostname === 'localhost'
  }

  arrayMove(arr: any[], fromIndex: number, toIndex: number) {
    var element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
  }

  /** accepts any number inc 0 and any string except empty or whitespace strings.
   *  else return SERIES_EMPTY_TEXT
   */
  checkForNullOrEmpty(value: string | number) {
    let isEmpty = false
    const isString = typeof value === 'string'
    if (isString) isEmpty = value.trim().length === 0
    else isEmpty = value === null || value === undefined

    return isEmpty ? SERIES_EMPTY_TEXT : value
  }

  sortByAttribute(attribute: string, isAscending = true, tieBreaker?: (a: any, b: any) => any) {
    const reverseMultiplier = isAscending ? 1 : -1

    return (o: any, p: any) => {
      let result
      const a = o[attribute]
      const b = p[attribute]

      if (a === b) {
        return tieBreaker ? tieBreaker(o, p) : 0
      }

      if (typeof a === typeof b) {
        result = a < b ? -1 : 1
      } else {
        result = typeof a < typeof b ? -1 : 1
      }

      return result * reverseMultiplier
    }
  }

  /** remove whiteSpace and ensure first char is lower case
   *  ASSUMPTION: first letter of subsequent words are capitalised
   *              (i.e. will not enforce camelCase)              */
  toAttributeFriendlyName(str: string): string {
    return this.lowerCaseFirstLetter(str).replace(' ', '')
  }

  lowerCaseFirstLetter(str: string): string {
    return str.charAt(0).toLocaleLowerCase() + str.slice(1)
  }

  groupBy(input: any[], key: string, keyFunc?: (item: any) => any): any[][] {
    return input.reduce((previous, currentItem) => {
      if (!currentItem[key]) {
        return previous
      }

      let groupKey = keyFunc ? keyFunc(currentItem[key]) : currentItem[key]

      if (!previous[groupKey]) {
        previous[groupKey] = []
      }

      previous[groupKey].push(currentItem)

      return previous
    }, [])
  }

  round(num: number, places: number): number {
    if (places < 0) {
      this.logger.log('utils', 'round', null, 'negative num')
      throw Error('negative number in round()')
    }
    const modifier = Math.pow(10, places)
    return Math.round((num + Number.EPSILON) * modifier) / modifier
  }

  getDefaultValueAggregationPrefix(type: ValueAggregationType): string {
    switch (type) {
      case ValueAggregationType.Average:
        return 'Average '
      case ValueAggregationType.Sum:
        return 'Sum of '
      case ValueAggregationType.Cumulative:
        return 'Cumulative '
    }
  }

  generateJobAddressString(address: JobAddress): string {
    if (!address) {
      return ''
    }

    let string = ''

    if (address.lotNumber && address.lotNumber.trim() !== '') {
      string = string.concat('Lot ' + address.lotNumber.trim())
    }
    if (address.streetNumber && address.streetNumber.trim() !== '') {
      string = string.concat(' #' + address.streetNumber.trim())
    }
    if (address.streetName1 && address.streetName1.trim() !== '') {
      string = string.concat(' ' + address.streetName1.trim())
    }
    if (address.streetName2 && address.streetName2.trim() !== '') {
      string = string.concat(' ' + address.streetName2.trim())
    }
    if (address.suburbTown && address.suburbTown.trim() !== '') {
      string = string.concat(' ' + address.suburbTown.trim())
    }
    if (address.postCode && address.postCode.trim() !== '') {
      string = string.concat(' ' + address.postCode.trim())
    }

    return string
  }

  getDynamicSeriesFromChartItem(
    item: GenericChartItem,
    removeSeriesWithNoItems = false
  ): SeriesValue[] {
    const fields = Object.keys(item)
    const filtered = fields.filter((s) => !KNOWN_CHART_ITEM_FIELDS.includes(s))

    let series = filtered.map((s) => ({
      name: s,
      value: item[s] ?? 0,
      numItems: item.seriesNumItems[s] ?? 0,
    }))

    if (removeSeriesWithNoItems) series = series.filter((s) => item.seriesNumItems[s.name])

    series = this.orderBy(series, ['value', 'numItems'], ['desc', 'desc'])
    return series
  }

  /** orderBy(users, ["name", "age"], ["asc", "desc"]),
      orderBy(users, ["name", "age"]); */
  orderBy<T>(arr: T[], props: (keyof T)[], orders?: ('asc' | 'desc')[]) {
    return [...arr].sort((a, b) =>
      props.reduce((acc, prop, i) => {
        if (acc === 0) {
          const [p1, p2] = orders && orders[i] === 'desc' ? [b[prop], a[prop]] : [a[prop], b[prop]]
          acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0
        }
        return acc
      }, 0)
    )
  }
}
