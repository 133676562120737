import { Injectable } from '@angular/core'
import * as dayjs from 'dayjs'
import { GROUP_KEYS_FUNCTION, PAST_DATE_RANGE_TYPES } from 'src/app/types/constants'
import { DateAxisType, DateRangeType } from 'src/app/types/enums'
import { WEEK_START } from './chart-datasource-provider.service'
import { LogService } from './log.service'
import { ChartConfig } from 'src/app/types/chart.model'

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService {
  constructor(private logger: LogService) {}

  getDatasourceProviderGroupKeyFunc(
    isDateGrouping: boolean,
    dateAxisTypeId?: DateAxisType
  ): GROUP_KEYS_FUNCTION {
    let groupKeyFunc: GROUP_KEYS_FUNCTION = (item: any, config: ChartConfig) => {
      return [item[config.argumentField]]
    }

    if (isDateGrouping) {
      switch (dateAxisTypeId) {
        case DateAxisType.Daily:
          groupKeyFunc = DateUtilsService.getDayKeyFunc
          break
        case DateAxisType.Weekly:
          groupKeyFunc = DateUtilsService.getWeekKeyFunc
          break
        case DateAxisType.Monthly:
          groupKeyFunc = DateUtilsService.getMonthKeyFunc
          break
        case DateAxisType.Yearly:
          groupKeyFunc = DateUtilsService.getYearKeyFunc
          break
        default:
          this.logger.log(
            'date-utils.service',
            'getDatasourceProviderGroupKeyFunc',
            null,
            'date grouping not implemented'
          )
          throw new Error('date grouping not implemented')
      }
    }

    return groupKeyFunc
  }

  getSimpleGroupKeyFunc(
    isDateGrouping: boolean,
    dateAxisTypeId?: DateAxisType
  ): (item: any) => any {
    let groupKeyFunc = (field: any) => {
      return field
    }

    if (isDateGrouping) {
      switch (dateAxisTypeId) {
        case DateAxisType.Daily:
          groupKeyFunc = DateUtilsService.getStartOfDay
          break
        case DateAxisType.Weekly:
          groupKeyFunc = DateUtilsService.getStartOfWeek
          break
        case DateAxisType.Monthly:
          groupKeyFunc = DateUtilsService.getStartOfMonth
          break
        case DateAxisType.Yearly:
          groupKeyFunc = DateUtilsService.getStartOfYear
          break
        default:
          this.logger.log(
            'date-utils.service',
            'getSimpleGroupKeyFunc',
            null,
            'date grouping not implemented'
          )
          throw new Error('date grouping not implemented')
      }
    }

    return groupKeyFunc
  }

  rangeAlignsToGrouping(grouping: DateAxisType, start: Date, end: Date): boolean {
    let period: dayjs.OpUnitType
    switch (grouping) {
      case DateAxisType.Daily:
        period = 'day'
        break
      case DateAxisType.Weekly:
        period = 'week'
        break
      case DateAxisType.Monthly:
        period = 'month'
        break
      case DateAxisType.Yearly:
        period = 'year'
        break
      default:
        this.logger.log(
          'date-utils.service',
          'rangeAlignsToGrouping',
          null,
          'date grouping not implemented'
        )
        throw new Error('date grouping not implemented')
    }

    const dayjsStart = dayjs(start).startOf('day')
    const dayjsEnd = dayjs(end).startOf('day')
    const startOfPeriod = dayjsStart.startOf(period)
    const endOfPeriod = dayjsEnd.endOf(period).startOf('day')

    return startOfPeriod.isSame(dayjsStart) && endOfPeriod.isSame(dayjsEnd)
  }

  getGroupingStartAndEndDate(rangeType: DateRangeType): [dayjs.Dayjs, dayjs.Dayjs] {
    const now = dayjs()

    switch (rangeType) {
      case DateRangeType.Custom:
      case DateRangeType['All Time']:
        throw Error('attempting to get dates for all-time or custom date range')
      case DateRangeType['Last 5 Years']:
        return [dayjs().subtract(5, 'year').startOf('year'), now]
      case DateRangeType['Last Year']:
        return [dayjs().subtract(1, 'year').startOf('year'), now]
      case DateRangeType['Last 6 Months']:
        return [dayjs().subtract(6, 'month').startOf('month'), now]
      case DateRangeType['Last 3 Months']:
        return [dayjs().subtract(3, 'month').startOf('month'), now]
      case DateRangeType['Last Month']:
        return [dayjs().subtract(1, 'month').startOf('month'), now]
      case DateRangeType['Next 5 Years']:
        return [now, dayjs().add(5, 'year').endOf('year')]
      case DateRangeType['Next Year']:
        return [now, dayjs().add(1, 'year').endOf('year')]
      case DateRangeType['Next 6 Months']:
        return [now, dayjs().add(6, 'month').endOf('month')]
      case DateRangeType['Next 3 Months']:
        return [now, dayjs().add(3, 'month').endOf('month')]
      case DateRangeType['Next Month']:
        return [now, dayjs().add(1, 'month').endOf('month')]
      default:
        this.logger.log(
          'date-utils.service',
          'getGroupingStartAndEndDate',
          null,
          'undefined range type'
        )
        throw Error('undefined range type')
    }
  }

  getDateRangeHTMLWithDates(
    dateRangeType: DateRangeType,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ) {
    if (dateRangeType == null || dateRangeType === DateRangeType['All Time'])
      return 'All time'

    if (dateRangeType === DateRangeType['Custom'])
      return this.getRangeText(dateFrom, dateTo)

    let rangeText: string
    const dates = this.getGroupingStartAndEndDate(dateRangeType)

    if (PAST_DATE_RANGE_TYPES.includes(DateRangeType[dateRangeType])) {
      rangeText = `${this.formatDateStandard(dates[0].toDate())} -> now`
    } // future date range type
    else rangeText = `now -> ${this.formatDateStandard(dates[1].toDate())}`

    const rangeSuffix = `<span class="smaller">(${rangeText})</span>`

    switch (dateRangeType) {
      case DateRangeType['Last 5 Years']:
        return `Last 5 Years ${rangeSuffix}`
      case DateRangeType['Last Year']:
        return `Last Year ${rangeSuffix}`
      case DateRangeType['Last 6 Months']:
        return `Last 6 months ${rangeSuffix}`
      case DateRangeType['Last 3 Months']:
        return `Last 3 Months ${rangeSuffix}`
      case DateRangeType['Last Month']:
        return `Last Month ${rangeSuffix}`
      case DateRangeType['Next 5 Years']:
        return `Next 5 Years ${rangeSuffix}`
      case DateRangeType['Next Year']:
        return `Next Year ${rangeSuffix}`
      case DateRangeType['Next 6 Months']:
        return `Next 6 Months ${rangeSuffix}`
      case DateRangeType['Next 3 Months']:
        return `Next 3 Months ${rangeSuffix}`
      case DateRangeType['Next Month']:
        return `Next Month ${rangeSuffix}`
      default:
        this.logger.log(
          'date-utils.service',
          'getDateRangeHTMLWithDates',
          null,
          'unhandled date range type'
        )
        throw Error('unhandled date range type in getDateRangeHTMLWithDates')
    }
  }

  /** assumes UTC format. attempt to stop timezone changing date */
  stringToDate(dateStr: string): Date {
    dateStr.slice(0)
    return new Date(dateStr.slice(0))
  }

  /** zeroes time - assumes date is correct regardless of timezone */
  UTCStringWithoutTimeFromDate(dateStr: Date | string): string {
    const date = new Date(dateStr)
    const twoDigitDate = ('0' + date.getDate()).slice(-2)
    const twoDigitMonth = ('0' + (date.getMonth() + 1)).slice(-2)
    return `${date.getFullYear()}-${twoDigitMonth}-${twoDigitDate}T00:00:00Z`
  }

  /** e.g. '1/5/2022' */
  formatDateStandard(date: Date | string | undefined): string {
    if (date == null) {
      this.logger.log('date-utils.service', 'formatDateStandard', null, 'no date')
      throw Error('no date in formatDateStandard')
    }

    if (typeof date === 'string') date = new Date(date)

    return `${(date as Date).getDate()}/${(date as Date).getMonth() + 1}/${(
      date as Date
    ).getFullYear()}`
  }

  /** e.g. '01/05/2022' */
  formatDatePadded(date: Date | string | undefined): string {
    if (date == null) {
      this.logger.log('date-utils.service', 'formatDateStandard', null, 'no date')
      throw Error('no date in formatDateStandard')
    }

    if (typeof date === 'string') date = new Date(date)

    return date.toLocaleDateString('en-au')
  }

  /** 'Month YYYY' */
  getDateMonthString(date: Date): string {
    return date.toLocaleString('default', { month: 'long', year: 'numeric' })
  }

  getDateYearString(date: Date): string {
    return date.getFullYear().toString()
  }

  getDateGroupingPeriodName(grouping: DateAxisType): string {
    switch (grouping) {
      case DateAxisType.Daily:
        return 'Day'
      case DateAxisType.Yearly:
        return 'Year'
      case DateAxisType.Monthly:
        return 'Month'
      case DateAxisType.Weekly:
        return 'Week starting'
      default:
        this.logger.log(
          'date-utils.service',
          'getDateGroupingPeriodName',
          null,
          'unhandled date axis grouping'
        )
        throw new Error('unhandled date axis grouping in chart')
    }
  }

  getDayJsPeriodName(grouping: DateAxisType): dayjs.ManipulateType {
    switch (grouping) {
      case DateAxisType.Weekly:
        return 'week'
      default:
        return this.getDateGroupingPeriodName(grouping).toLowerCase() as dayjs.ManipulateType
    }
  }

  static getPeriodKeysBetween(one: Date, two: Date, periodType: DateAxisType) {
    switch (periodType) {
      case DateAxisType.Daily:
        return DateUtilsService.getDayKeysBetween(one, two)
      case DateAxisType.Weekly:
        return DateUtilsService.getWeekKeysBetween(one, two)
      case DateAxisType.Monthly:
        return DateUtilsService.getMonthKeysBetween(one, two)
      case DateAxisType.Yearly:
        return DateUtilsService.getYearKeysBetween(one, two)
      default:
        throw new Error('date grouping not implemented')
    }
  }

  private static getDayKeysBetween(dateOne: Date, dateTwo: Date): Date[] {
    const timeDiff = Math.abs(dateOne.getTime() - dateTwo.getTime())
    const daysBetween = Math.ceil(timeDiff / (1000 * 3600 * 24))

    const keys = []
    const firstDay = this.getStartOfDay(dateOne)
    const start = dayjs(firstDay)
    keys.push(firstDay)
    for (let index = 1; index <= daysBetween; index++) {
      const next = start.add(index, 'days')
      keys.push(next.toDate())
    }

    return keys
  }

  private static getWeekKeysBetween(dateOne: Date, dateTwo: Date): Date[] {
    const week = 7 * 24 * 60 * 60 * 1000
    const startWeek1 = DateUtilsService.getStartOfWeek(dateOne)
    const startWeek2 = DateUtilsService.getStartOfWeek(dateTwo)
    const weeksBetween = Math.ceil((startWeek2.getTime() - dateOne.getTime()) / week)

    const keys = []
    const start = dayjs(startWeek1)
    keys.push(startWeek1)
    for (let index = 1; index <= weeksBetween; index++) {
      const next = start.add(index, 'weeks')
      keys.push(next.toDate())
    }

    return keys
  }

  private static getMonthKeysBetween(dateOne: Date, dateTwo: Date): Date[] {
    const monthsBetween =
      (dateTwo.getFullYear() - dateOne.getFullYear()) * 12 +
      (dateTwo.getMonth() - dateOne.getMonth())

    const keys = []
    const firstMonth = this.getStartOfMonth(dateOne)
    const start = dayjs(firstMonth)
    keys.push(firstMonth)
    for (let index = 1; index <= monthsBetween; index++) {
      const next = start.add(index, 'months')
      keys.push(next.toDate())
    }

    return keys
  }

  private static getYearKeysBetween(dateOne: Date, dateTwo: Date): Date[] {
    const yearsBetween = dateTwo.getFullYear() - dateOne.getFullYear()

    const keys = []
    const firstMonth = this.getStartOfYear(dateOne)
    const start = dayjs(firstMonth)
    keys.push(firstMonth)
    for (let index = 1; index <= yearsBetween; index++) {
      const next = start.add(index, 'years')
      keys.push(next.toDate())
    }

    return keys
  }

  private getRangeText(dateFrom: Date | undefined, dateTo: Date | undefined) {
    if (!dateFrom && !dateTo) {
      this.logger.log('date-utils.service', 'getRangeText', null, 'no start or end date')
      throw Error('no start or end date in getCustomRangeText')
    }

    return !dateFrom
      ? 'Everything to ' + this.formatDateStandard(dateTo!)
      : !dateTo
      ? 'Everything from ' + this.formatDateStandard(dateFrom)
      : this.formatDateStandard(dateFrom) + ' -> ' + this.formatDateStandard(dateTo)
  }

  private static getDayKeyFunc = (item: any, config: ChartConfig): Date[] => {
    const date: Date = item[config.argumentField]
    return [DateUtilsService.getStartOfDay(date)]
  }

  private static getWeekKeyFunc = (item: any, config: ChartConfig): Date[] => {
    const date: Date = item[config.argumentField]
    return [DateUtilsService.getStartOfWeek(date)]
  }

  private static getMonthKeyFunc = (item: any, config: ChartConfig): Date[] => {
    const date: Date = item[config.argumentField]
    return [DateUtilsService.getStartOfMonth(date)]
  }

  private static getYearKeyFunc = (item: any, config: ChartConfig): Date[] => {
    const date: Date = item[config.argumentField]
    return [DateUtilsService.getStartOfYear(date)]
  }

  private static getStartOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
  }

  private static getStartOfWeek(date: Date): Date {
    const copy = new Date(date)
    while (copy.getDay() != WEEK_START) {
      copy.setDate(copy.getDate() - 1)
    }

    // 'May W2 2022' string format
    // const firstWeekday = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    // const offsetDate = date.getDate() + firstWeekday - 1;
    // const weekNum = Math.floor(offsetDate / 7) + 1;
    // return `${month} W${weekNum} ${date.getUTCFullYear()}`;

    return new Date(copy.getFullYear(), copy.getMonth(), copy.getDate())
  }

  private static getStartOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1)
  }

  private static getStartOfYear(date: Date): Date {
    return new Date(date.getFullYear(), 1)
  }
}
