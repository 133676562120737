<div class="grid-container">
  <mat-toolbar color="secondary" class="header">
    <mat-form-field *ngIf="dashboardsExist" appearance="standard" class="dash-select"
      [ngClass]="{'smaller-font' : screen.isMedOrLess}">
      <mat-select [value]="dashboardsService.selectedDashboardId"
        (selectionChange)="dashboardSelected($event)">
        <mat-select-trigger>
          {{dashboardsService.selectedDashboardName}}
          <mat-icon *ngIf="dashboardsService.currentDashIsDefault" class="m-l-5 vertical-bottom"
            [ngClass]="{'smaller-icon' : screen.isMedOrLess}">
            favorite
          </mat-icon>
        </mat-select-trigger>
        <mat-option *ngFor="let dash of dashboardsService.dashboards" [value]="dash.id">
          {{dash.name}}
          <mat-icon *ngIf="dashboardsService.dashIsDefault(dash.id)" class="m-l-5 vertical-sub"
            [ngClass]="{'smaller-icon' : screen.isMedOrLess}">
            favorite
          </mat-icon>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span class="header-btns">
      <button *ngIf="dashboardsExist" [class.mat-stroked-button]="!screen.isXSmall" [class.mat-mini-fab]="screen.isXSmall"
        color="primary" class="m-l-15 primary-btn mat-elevation-z0" (click)="addEditDashboard(false)">
        {{screen.isXSmall ? '' : 'Edit Dashboard'}}
        <mat-icon *ngIf="screen.isXSmall" class="vertical-middle">edit</mat-icon>
      </button>
      <button [class.mat-stroked-button]="!screen.isXSmall" [class.mat-mini-fab]="screen.isXSmall"
        color="primary" class="vertical-middle primary-btn mat-elevation-z0" (click)="addEditDashboard(true)">
        {{screen.isXSmall ? '' : 'Add Dashboard'}}
        <mat-icon *ngIf="screen.isXSmall" class="vertical-middle">add</mat-icon>
      </button>
    </span>
  </mat-toolbar>

  <app-spinner *ngIf="loading; else loaded"></app-spinner>

  <ng-template #loaded>
    <app-dashboard *ngIf="dashboardsExist" [dashboardId]="dashboardsService.selectedDashboardId"></app-dashboard>
  
    <div *ngIf="!error && !dashboardsExist" class="info-box m-50">
      You don't appear to have any Dashboards.
      You can create one with the button above.
    </div>
    <div *ngIf="error" class="error-box m-50">
      There was an error fetching the dashboards.
      <br>Please try again in a moment.
    </div>
  </ng-template>
</div>