<div *ngIf="chartConfig && chartItem"
  [ngClass]="{'tooltip' : toolTipMode, 'modal' : !toolTipMode}">
  <div class="tooltip-top-section">
    <div>
      <div *ngIf="chartConfig.tooltipTitle" class="tooltip-title"
        [innerHTML]="chartConfig.tooltipTitle">
      </div>
      <div class="tooltip-section">
        <b class="tooltip-label">{{ toolTipPrefix }}:</b>
        <span class="tooltip-highlight text-right">
          {{ getToolTipArgument(chartItem) }}
        </span>
      </div>
      <ng-container *ngIf="!chartConfig.hideDefaultValueTooltipLines">
        <div *ngIf="!valueAggregationIsCount" class="tooltip-section m-t-6">
          <b class="tooltip-label">{{ toolTipValueHeader }}:</b>
          <span class="tooltip-highlight text-right">
            {{ valueText | number }}
          </span>
        </div>
        <div *ngIf="!chartItem.hideNumItems" class="tooltip-section m-t-6">
          <span class="tooltip-label" [class.bold]="valueAggregationIsCount">
            Number of items:
          </span>
          <span class="tooltip-highlight text-right">
            {{ numItems | number }}
          </span>
        </div>
        <ng-container *ngIf="multipleSeries">
          <div class="tooltip-section m-b-2 m-t-6">
            <span class="tooltip-label bold">Splitting by</span>
            <span class="tooltip-highlight text-right">{{seriesGroupByName}}</span>
          </div>
          <div *ngIf="manySeries && toolTipMode; else dynamicSeriesBlock" class="center">
            <i><small>
              Too many categories to show here, click the {{ chartItemName }} on the graph for breakdown
            </small></i>
          </div>
          <ng-template #dynamicSeriesBlock>
            <div class="max-h-200 overflow-auto">
              <div *ngFor="let series of dynamicSeriesForItem">
                <div class="tooltip-section">
                  <span class="tooltip-label m-l-2"><i>{{series.name}}</i></span>
                  <span class="tooltip-highlight not-bold text-right m-r-2"><i>
                    {{getSeriesNumItemsText(series)}}
                    <span class="m-l-2">{{series.value | number}}</span>
                  </i></span>
                </div>
              </div>
              <div *ngIf="showFilteredCategoriesWarning" class="m-t-5 p-3 center font-x-sml opacity-80">
                Note: only options that appear at least once in the chart (given selected time range and filters) will be shown here
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
      <div *ngFor="let customValue of chartItem.additionalPointInfoValues"
        class="tooltip-section m-t-6">
        <span class="tooltip-label">
          {{ customValue.label }}
        </span>
        <span class="tooltip-highlight text-right" [class.not-bold]="customValue.notBold">
          {{ customValue.isNumber ? (customValue.value | number) : customValue.value }}
        </span>
      </div>
    </div>
  </div>

  <div class="settings-section p-3">
    <div class="tooltip-header">CHART SETTINGS</div>
    <div class="tooltip-section">
      Datasource:
      <span class="tooltip-highlight">
        {{ chartConfig.dataSourceId | datasourceNamePipe }}
      </span>
    </div>
    <!-- Task Type? -->
    <div class="tooltip-section">
      Grouping by:
      <span class="tooltip-highlight">{{ argumentDisplayName }}</span>
    </div>
    <div class="tooltip-section">
      Date Filter Field:
      <span class="tooltip-highlight">{{ dateFilterDisplayName }}</span>
    </div>
    <div class="tooltip-section">
      Date Range:
      <span class="tooltip-highlight" [innerHTML]="getDateRangeText()"></span>
    </div>
    <div class="tooltip-section">
      Filters Active:
      <span class="tooltip-highlight">
        {{ chartConfig.filters ? 'Yes' : 'No' }}
      </span>
    </div>
  </div>

  <div *ngIf="toolTipMode && chartItem.jobIds?.length && !screen.isXSmall" class="more-info">
    Click the {{ chartItemName }} on the graph for more info
  </div>
</div>
