export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: 'chart'
  },
  // {
  //   text: 'Training Docs',
  //   path: '/training',
  //   icon: 'user'
  // },
  {
    text: 'Applications',
    icon: 'globe',
    items: [
      {
        text: 'Truth Engine',
        icon: 'link',
        link: 'truthEngineAppUrl'
      },
      {
        text: 'Tracking',
        icon: 'link',
        link: 'trackingAppUrl'
      },
      {
        text: 'Estimating',
        icon: 'link',
        link: 'estimatingAppUrl'
      },
      {
        text: 'Purchase Orders',
        icon: 'link',
        link: 'purchaseOrdersAppUrl'
      },
      {
        text: 'Config',
        icon: 'link',
        link: 'configAppUrl'
      },
      {
        text: 'Admin',
        icon: 'link',
        link: 'adminAppUrl'
      }
    ]
  }
];
