import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import DataSource from 'devextreme/data/data_source'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { ConfirmService } from 'src/app/shared/components/confirm/confirm.service'
import { DecimalMaskPipe } from 'src/app/shared/decimal-place.pipe'
import { CardApiService } from 'src/app/shared/services/api/card.api.service'
import { TasksApiService } from 'src/app/shared/services/api/tasks.api.service'
import { DatasourceConfigService } from 'src/app/shared/services/datasource-config.service'
import { DateUtilsService } from 'src/app/shared/services/date-utils.service'
import { LogService } from 'src/app/shared/services/log.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { UtilsService } from 'src/app/shared/services/utils.service'
import { ChartField } from 'src/app/types/chart.model'
import { DEFAULT_RANGE, DEFAULT_VALUE_FIELD } from 'src/app/types/constants'
import { AppMatCard } from 'src/app/types/dashboard.model'
import { PatchDashCardRequest } from 'src/app/types/request/dashboard.req'
import {
  ChartDataSource,
  ChartType,
  DateAxisType,
  DateRangeType,
  TaskDurationAggregation,
  ValueAggregationType,
} from 'src/app/types/enums'
import { MAX_DASHBOARD_NAME_LEN } from 'src/config/global-config'
import { DashboardService } from '../dashboard/dashboard.service'

export const DISABLED_SEPARATE_SERIES = 'DO NOT GROUP'
export const DISABLED_SEPARATE_SERIES_OPTION: ChartField = {
  displayName: DISABLED_SEPARATE_SERIES,
  dataField: DISABLED_SEPARATE_SERIES,
  dataType: 'boolean',
}

@Component({
  selector: 'app-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrls: ['./edit-card.component.scss'],
})
export class EditCardComponent implements OnInit, OnDestroy {
  MAX_NAME_LEN = MAX_DASHBOARD_NAME_LEN

  card?: AppMatCard
  form?: UntypedFormGroup

  datasourceOptions = Object.keys(ChartDataSource)
    .filter((k) => isNaN(Number(k)))
    .sort()
  taskTypes: DataSource | undefined
  chartOptions = Object.keys(ChartType).filter((k) => isNaN(Number(k)))
  groupOptions = Object.keys(DateAxisType).filter((k) => isNaN(Number(k)))
  taskDurationOptions = Object.keys(TaskDurationAggregation).filter((k) => isNaN(Number(k)))
  seriesOptions: ChartField[] = []
  rangeOptions?: string[]
  aggregationOptions = Object.keys(ValueAggregationType).filter((k) => isNaN(Number(k)))
  dataSourceFilterFields?: any[]
  argFieldOptions: ChartField[] = []
  argFieldIsDateType = false
  valueFieldOptions: ChartField[] = []
  usingCustomValueField = false
  dateFilterFieldOptions: ChartField[] = []
  filter: any
  chartColour: string
  showGroupBySeries = false
  hideStartingValue = false
  hideChartColorSelection = false

  rangeTypeEnum = DateRangeType

  loading = true
  tasksError = false
  rangeNotAlignToGrouping = false
  dynamicDateRange = false
  cumulativeAggregation = false

  isTaskSource = false
  isTaskDurationSource = false

  private readonly destroying = new Subject<null>()

  constructor(
    private UntypedFormBuilder: UntypedFormBuilder,
    private dashboardService: DashboardService,
    private datasourceConfigService: DatasourceConfigService,
    private cardApi: CardApiService,
    private notiService: NotificationService,
    private tasksApi: TasksApiService,
    private dateUtils: DateUtilsService,
    private utils: UtilsService,
    private logger: LogService,
    private confirmService: ConfirmService,
    private decimalMaskPipe: DecimalMaskPipe,
    private dialogRef: MatDialogRef<EditCardComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.card = data.card
    this.chartColour = this.utils.getThemePrimaryColour()
  }

  ngOnInit() {
    if (!this.card) {
      this.logger.log('edit-card', 'onInit', 'no card in edit-card')
      throw new Error('no card in edit-card')
    }

    if (this.card.filters) this.filter = JSON.parse(this.card.filters)

    if (this.card.chartColour) this.chartColour = this.card.chartColour

    if (this.card.dataSourceId || this.card.dataSourceId === 0)
      this.getSeriesOptions(this.card.dataSourceId)

    if (this.card.taskDurationAggregation == undefined)
      this.card.taskDurationAggregation = TaskDurationAggregation.Week

    this.initForm()
    this.getJobMasters()
  }

  save() {
    this.loading = true
    this.form?.disable({ emitEvent: false })

    if (this.card && this.form) {
      const start: Date = this.form.controls.start.value
      const end: Date = this.form.controls.end.value

      const model = {
        title: this.form.controls.name.value,
        dataSourceId:
          ChartDataSource[this.form.controls.datasource.value as keyof typeof ChartDataSource],
        taskmasterId: this.form.controls.taskType.value,
        taskmaster2Id: this.form.controls.taskType2.value,
        task1DateField: this.form.controls.task1DateField.value,
        task2DateField: this.form.controls.task2DateField.value,
        taskDurationAggregation:
          TaskDurationAggregation[
            this.form.controls.taskDurationOption.value as keyof typeof TaskDurationAggregation
          ],
        chartTypeId: ChartType[this.form.controls.chartType.value as keyof typeof ChartType],
        dateAxisTypeId:
          DateAxisType[this.form.controls.grouping.value as keyof typeof DateAxisType],
        valueField: this.form.controls.valueField.value,
        valueAggregationTypeId:
          ValueAggregationType[
            this.form.controls.valueAggregation.value as keyof typeof ValueAggregationType
          ],
        startingValue: this.form.controls.startingValue.value,
        expectedPercentCancellation: this.form.controls.percentCancellation.value,
        argumentField: this.form.controls.argumentField.value,
        splitByField: this.form.controls.splitByField.value.dataField,
        showFilteredCategoriesOnly: this.form.controls.showFilteredCategoriesOnly.value,
        dateFilterField: this.form.controls.dateFilterField.value,
        dateRangeTypeId:
          DateRangeType[this.form.controls.rangeType.value as keyof typeof DateRangeType],
        dateFrom: this.dynamicDateRange
          ? undefined
          : start
          ? this.dateUtils.UTCStringWithoutTimeFromDate(start)
          : undefined,
        dateTo: this.dynamicDateRange
          ? undefined
          : end
          ? this.dateUtils.UTCStringWithoutTimeFromDate(end)
          : undefined,
        filters: this.filter ? JSON.stringify(this.filter) : '',
        chartColour: this.chartColour,
        targetValue: this.form.controls.targetValue.value,
      } as PatchDashCardRequest

      this.cardApi.editCard(this.card?.id, model).subscribe({
        next: (updatedCard) => {
          this.dashboardService.updateCard(updatedCard)
          this.notiService.showSuccess('Chart Updated')
          this.dialogRef.close()
        },
        error: (err) => {
          this.loading = false
          this.form?.enable()
          this.notiService.handleError(err)
          this.logger.log('edit-card', 'save', err)
        },
      })
    }
  }

  delete() {
    this.confirmService
      .confirmDelete(
        `Are you sure you want to DELETE the chart <b>${
          this.card?.title ?? 'ERROR'
        }</b>? <br><br> This is irreversible!`
      )
      .subscribe((confirm) => {
        if (!this.card) {
          this.logger.log('edit-card', 'delete', null, 'no card')
          throw new Error('no card to delete')
        }

        if (confirm)
          this.dashboardService
            .removeCard(this.card)
            .pipe(takeUntil(this.destroying))
            .subscribe({
              next: () => this.dialogRef.close(),
              error: (err) => {
                this.notiService.handleError(err)
                this.logger.log('edit-card', 'delete', err)
              },
            })
      })
  }

  rangeInvalidForGrouping(range: string) {
    if (!this.argFieldIsDateType) {
      return false
    }
    return (
      this.form?.controls.grouping.value === DateAxisType[DateAxisType.Yearly] &&
      range.toLowerCase().indexOf('month') > -1
    )
  }

  ngOnDestroy(): void {
    this.destroying.next(null)
    this.destroying.complete()
  }

  private getJobMasters() {
    this.tasksApi.getGroupedTaskMastersForCurrentCompany().subscribe({
      next: (res) => {
        this.loading = false
        this.form?.enable()
        this.tasksError = false
        this.taskTypes = new DataSource({
          store: res,
          group: { selector: 'taskTypeDescription' },
        })
      },
      error: (err) => {
        this.loading = false
        this.form?.enable()
        this.tasksError = true
        this.logger.log('edit-card', 'getJobMasters', err)
      },
    })
  }

  private initForm() {
    if (this.card) {
      this.form = this.UntypedFormBuilder.group({
        name: [this.card.title, [Validators.maxLength(this.MAX_NAME_LEN), Validators.minLength(2)]],
        datasource: [
          this.card.dataSourceId != undefined ? ChartDataSource[this.card.dataSourceId] : undefined,
          [Validators.required],
        ],
        taskType: [this.card.taskMasterId, [this.requiredIfTaskDatasource()]],
        task1DateField: [this.card.task1DateField, [this.requiredIfTaskDurationDatasource()]],
        taskType2: [this.card.taskMaster2Id, [this.requiredIfTaskDurationDatasource()]],
        task2DateField: [this.card.task1DateField, [this.requiredIfTaskDurationDatasource()]],
        chartType: [
          this.card.chartTypeId != undefined ? ChartType[this.card.chartTypeId] : undefined,
          [Validators.required],
        ],
        valueField: [this.card.valueField, [Validators.required]],
        taskDurationOption: [
          this.card.taskDurationAggregation != undefined
            ? TaskDurationAggregation[this.card.taskDurationAggregation]
            : undefined,
          [this.requiredIfTaskDurationDatasource()],
        ],
        valueAggregation: [
          this.card.valueAggregationTypeId != undefined
            ? ValueAggregationType[this.card.valueAggregationTypeId]
            : undefined,
          [this.requiredIfCustomValueField()],
        ],
        splitByField: [
          this.card.splitByField
            ? this.getSeriesGroupingOption(this.card.splitByField)
            : DISABLED_SEPARATE_SERIES_OPTION,
        ],
        showFilteredCategoriesOnly: [this.card.showFilteredCategoriesOnly],
        startingValue: [this.card.startingValue ?? 0],
        percentCancellation: [this.card.expectedPercentCancellation ?? 0],
        argumentField: [this.card.argumentField, [Validators.required]],
        dateFilterField: [this.card.dateFilterField, [Validators.required]],
        rangeType: [
          this.card.dateRangeTypeId != undefined
            ? DateRangeType[this.card.dateRangeTypeId]
            : DEFAULT_RANGE,
          [Validators.required],
        ],
        start: [this.card.dateFrom ?? undefined, [this.requiredIfCustomRange()]],
        end: [this.card.dateTo ?? undefined, [this.requiredIfCustomRange()]],
        grouping: [
          this.card?.dateAxisTypeId != undefined
            ? DateAxisType[this.card.dateAxisTypeId]
            : undefined,
          [this.requiredIfDateArgument()],
        ],
        targetValue: [this.card.targetValue],
      })
      // this.form.disable();

      this.subscribeToFormChanges(this.form)
    }
  }

  private getSeriesGroupingOption(groupByField: string): ChartField {
    if (!this.seriesOptions) throw Error('no chart options in getSeriesGroupingOption')

    const match = this.seriesOptions.find((o) => o.dataField == groupByField)
    if (!match) throw Error('could not find matching series grouping option')

    return match
  }

  private getSeriesOptions(src: ChartDataSource) {
    this.seriesOptions = [DISABLED_SEPARATE_SERIES_OPTION].concat(
      this.datasourceConfigService.getDatasourceSeriesGroupFields(src)
    )
  }

  private subscribeToFormChanges(form: UntypedFormGroup) {
    form
      .get('datasource')
      ?.valueChanges.pipe(filter((source) => source!!))
      .subscribe((source: string) => {
        this.checkDatasourceType(source)
        this.adjustFormOnDatasourceSelection(form, source)
        this.updateFormValidity()
      })

    form.get('rangeType')?.valueChanges.subscribe((rangeType: string) => {
      const start = this.form?.get('start')
      const end = this.form?.get('end')
      if (rangeType == DateRangeType[DateRangeType.Custom]) {
        start?.setValue(this.card?.dateFrom)
        end?.setValue(this.card?.dateTo)
      }
      if (
        rangeType !== DateRangeType[DateRangeType['All Time']] &&
        rangeType !== DateRangeType[DateRangeType['Custom']]
      ) {
        this.dynamicDateRange = true
        const [start, end] = this.dateUtils.getGroupingStartAndEndDate(
          DateRangeType[rangeType as keyof typeof DateRangeType]
        )
        form.controls.start.setValue(start.toDate())
        form.controls.end.setValue(end.toDate())
      } else {
        this.dynamicDateRange = false
      }
    })

    form.get('grouping')?.valueChanges.subscribe(() => {
      if (this.rangeInvalidForGrouping(this.form?.controls.rangeType.value)) {
        this.form?.controls.rangeType.setValue(undefined)
      }
    })

    form.get('start')?.valueChanges.subscribe(() => {
      this.checkGroupingAlignsToCustomRange()
    })

    form.get('end')?.valueChanges.subscribe(() => {
      this.checkGroupingAlignsToCustomRange()
    })

    form.get('argumentField')?.valueChanges.subscribe((argField) => {
      this.argFieldIsDateType =
        this.argFieldOptions.filter((af) => af.dataField === argField)[0]?.dataType === 'date'

      this.checkGroupingAlignsToCustomRange()
    })

    form.get('valueField')?.valueChanges.subscribe((valueField) => {
      this.usingCustomValueField = this.customValueField(valueField)
    })

    form.get('valueAggregation')?.valueChanges.subscribe((valueAgg) => {
      this.cumulativeAggregation =
        valueAgg === ValueAggregationType[ValueAggregationType.Cumulative]
    })

    form.get('percentCancellation')?.valueChanges.subscribe((percentCancellation) => {
      const maskedVal = this.decimalMaskPipe.transform(percentCancellation)
      if (percentCancellation !== maskedVal)
        this.form?.controls.percentCancellation.setValue(maskedVal)
    })

    form.get('splitByField')?.valueChanges.subscribe((splitBy) => {
      if (splitBy.dataField === DISABLED_SEPARATE_SERIES) {
        this.hideStartingValue = false
        this.hideChartColorSelection = false
      } else {
        this.hideStartingValue = true
        this.hideChartColorSelection = true
      }
    })
  }

  private adjustFormOnDatasourceSelection(form: UntypedFormGroup, source: string) {
    const src = ChartDataSource[source as keyof typeof ChartDataSource]
    if (src == null) {
      this.logger.log(
        'edit-card',
        'adjustFormOnDatasourceSelection',
        "couldn't interpret source string"
      )
      throw Error("couldn't interpret source string in adjustFormOnDatasourceSelection")
    }

    this.showGroupBySeries = src !== ChartDataSource.TaskDurations
    if (this.showGroupBySeries) this.getSeriesOptions(src)

    this.rangeOptions = this.datasourceConfigService.getDateRangeOptions(src)

    this.dataSourceFilterFields = this.datasourceConfigService.getDatasourceFilterFields(src)
    this.argFieldOptions = this.datasourceConfigService.getDatasourceArgumentFields(src)
    this.valueFieldOptions = this.datasourceConfigService.getDatasourceValueFields(src)
    this.dateFilterFieldOptions = this.datasourceConfigService.getDatasourceDateFilterFields(src)

    // reset if user has changed the datasource
    if (form.controls.datasource.dirty) {
      this.form?.controls.valueField.setValue(undefined)
      this.form?.controls.argumentField.setValue(undefined)
      this.form?.controls.dateFilterField.setValue(undefined)
      this.filter = undefined
    }

    // set defaults for datasource if no values
    if (form.controls.valueAggregation.value == null)
      form.controls.valueAggregation.setValue(
        ValueAggregationType[this.datasourceConfigService.getDefaultValueAggregation(src)]
      )
    if (form.controls.grouping.value == null)
      form.controls.grouping.setValue(
        DateAxisType[this.datasourceConfigService.getDefaultDateGrouping(src)]
      )
    if (form.controls.chartType.value == null)
      form.controls.chartType.setValue(
        ChartType[this.datasourceConfigService.getDefaultChartType(src)]
      )
    const defaultDateFilter = this.dateFilterFieldOptions.filter((df) => df.isDefault)
    if (form.controls.dateFilterField.value == null && defaultDateFilter.length) {
      form.controls.dateFilterField.setValue(defaultDateFilter[0].dataField)
    }
    const defaultValue = this.valueFieldOptions.filter((df) => df.isDefault)
    if (form.controls.valueField.value == null && defaultValue.length) {
      form.controls.valueField.setValue(defaultValue[0].dataField)
    }
    const defaultArg = this.argFieldOptions.filter((df) => df.isDefault)
    if (form.controls.argumentField.value == null && defaultArg.length) {
      form.controls.argumentField.setValue(defaultArg[0].dataField)
    }

    // if there is only one option, set and disable
    if (this.valueFieldOptions.length === 1) {
      form.controls.valueField.setValue(this.valueFieldOptions[0].dataField)
      // doesn't work sometimes - have to use 'disabled' attribute in html despite warning
      // form.controls.valueField.disable();
    }
    if (this.argFieldOptions.length === 1) {
      form.controls.argumentField.setValue(this.argFieldOptions[0].dataField)
      // form.controls.argumentField.disable();
    }
  }

  private updateFormValidity() {
    this.form?.controls.task1DateField.updateValueAndValidity()
    this.form?.controls.task2DateField.updateValueAndValidity()
    this.form?.controls.taskType.updateValueAndValidity()
    this.form?.controls.taskType2.updateValueAndValidity()
    this.form?.controls.argumentField.updateValueAndValidity()
    this.form?.controls.valueField.updateValueAndValidity()
    this.form?.controls.dateFilterField.updateValueAndValidity()
  }

  private checkDatasourceType(source: string) {
    const datasource = ChartDataSource[source as keyof typeof ChartDataSource]

    this.isTaskSource = this.datasourceConfigService.datasourceUsesTaskSelection(datasource)
    this.isTaskDurationSource = datasource === ChartDataSource.TaskDurations
  }

  private checkGroupingAlignsToCustomRange() {
    if (
      this.form?.controls.rangeType.value === DateRangeType[DateRangeType.Custom] &&
      this.form?.controls.start.value &&
      this.form?.controls.end.value &&
      this.argFieldIsDateType
    ) {
      this.rangeNotAlignToGrouping = !this.dateUtils.rangeAlignsToGrouping(
        DateAxisType[this.form?.controls.grouping.value as keyof typeof DateAxisType],
        this.form?.controls.start.value,
        this.form?.controls.end.value
      )
    } else {
      this.rangeNotAlignToGrouping = false
    }
  }

  private requiredIfCustomRange(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.form?.controls.rangeType.value == DateRangeType[DateRangeType.Custom]) {
        return Validators.required(control)
      } else {
        return null
      }
    }
  }

  private requiredIfDateArgument(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.form?.controls.grouping.value?.dataType === 'date') {
        return Validators.required(control)
      } else {
        return null
      }
    }
  }

  private requiredIfCustomValueField(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.customValueField(this.form?.controls.valueField.value)) {
        return Validators.required(control)
      } else {
        return null
      }
    }
  }

  private customValueField(valueField?: string): boolean {
    return valueField != null && valueField != DEFAULT_VALUE_FIELD
  }

  private requiredIfTaskDatasource(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.form?.controls.datasource.value === ChartDataSource[ChartDataSource.Tasks]) {
        return Validators.required(control)
      } else {
        return null
      }
    }
  }

  private requiredIfTaskDurationDatasource(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.form?.controls.datasource.value === ChartDataSource[ChartDataSource.TaskDurations]) {
        return Validators.required(control)
      } else {
        return null
      }
    }
  }
}
