import { Observable } from 'rxjs'
import { catchError, shareReplay } from 'rxjs/operators'

export interface TimestampObservableCache<T> {
  expires: number
  observable: Observable<T>
}

export class TimedCache<T> {
  private DEFAULT_TIMEOUT = 1000 * 60 * 60

  private cache: { [id: number | string]: TimestampObservableCache<T> } = {}

  getCacheItem(key: number | string): Observable<T> | undefined {
    let cacheItem = this.cache[key]

    if (!cacheItem) {
      return undefined
    }

    if (cacheItem.expires <= Date.now()) {
      this.deleteCacheItem(key)
      return undefined
    }

    return cacheItem?.observable
  }

  /** ensure the caller uses the returned observable which has been modified */
  setCacheItem(
    key: number | string,
    obs$: Observable<T>,
    timeout = this.DEFAULT_TIMEOUT
  ): Observable<T> {
    const EXPIRES = Date.now() + timeout
    obs$ = obs$.pipe(
      shareReplay({ bufferSize: 1, refCount: false }),
      catchError((err) => {
        this.deleteCacheItem(key)
        throw err
      })
    )
    this.cache[key] = {
      expires: EXPIRES,
      observable: obs$,
    } as TimestampObservableCache<T>

    return obs$
  }

  deleteCacheItem(key: number | string) {
    delete this.cache[key]
  }
}
