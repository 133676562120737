import { Injectable } from '@angular/core';
import { HttpHelperService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private httpHelper: HttpHelperService,
    private http: HttpClient
  ) { }

  loginLog(email: string, success: boolean, msg: string) {
    console.log(msg);
    const url = environment.apiUrl + 'login-log';
    const log = {
      'emailAddress': email,
      'isSuccessful': success,
      'errorMessage': msg,
      'applicationId': environment.appId,
      'applicationVersion': environment.fullVersion
    };

    this.http.post(url, log, this.httpHelper.httpOptions)
      .subscribe();
  }

  /* Pass null as error for msg only error-log */
  log(component: string, method: String, error: any, errorMsg = '', print = false) {
    const errType = `App-${environment.appId}_v${environment.fullVersion}_${component}::${method}`;

    if (error) {
      if (!error.error || !error.error.message) {
        errorMsg = errorMsg + `. STATUS: ${error.status}. ERROR OBJ: ${JSON.stringify(error)}`;
      } else {
        errorMsg = errorMsg + `. STATUS: ${error.status}. MSG: ${error.error.message}`;
      }
    }

    if (print) {
      console.error(errType + ' - ' + errorMsg);
    }

    this.writeLog(errType, errorMsg); // if not local
  }

  writeLog(errType: string, errString: string) {
    if (environment.local) {
      console.log('not writing to log as in local env')
      return
    }

    const url = environment.apiUrl + 'errorlog';
    const log = {
      'errorType': errType,
      'errorString': errString
    };
    this.http.post(url, log, this.httpHelper.httpOptions)
      .subscribe();
  }
}
