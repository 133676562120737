import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScreenService } from 'src/app/shared/services';
import { JobsApiService } from 'src/app/shared/services/api/jobs.api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ChartConfig, GenericChartItem } from 'src/app/types/chart.model';
import { JobDto, ProcessedJob } from 'src/app/types/dto/job.dto';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-point-dialog',
  templateUrl: './point-dialog.component.html',
  styleUrls: ['./point-dialog.component.scss']
})
export class PointDialogComponent implements OnDestroy {
  chartConfig?: ChartConfig;
  chartItem?: GenericChartItem;
  valueText?: string;
  chartTitle?: string;
  chartHeight = '';

  loading = true;
  smallScreen = false;
  private readonly destroying = new Subject<null>();

  jobs: ProcessedJob[] = [];

  constructor(
    private screen: ScreenService,
    private jobApi: JobsApiService,
    private authService: AuthService,
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) data: any ) {
      this.chartItem = data.chartItem;
      this.chartConfig = data.chartConfig;
      this.valueText = data.valueText;
      this.chartTitle = data.chartTitle;

      if (this.chartItem?.jobIds?.length) {
        this.chartHeight = this.chartItem.jobIds.length > 4
          ? '350px'
          : '';

        const $obs: Observable<ProcessedJob>[] = [];
        this.chartItem.jobIds.forEach(jobId =>
          $obs.push(this.jobApi.getJob(jobId))
        )

        forkJoin($obs)
          .pipe(takeUntil(this.destroying))
          .subscribe(j => {
            this.jobs = j
            this.loading = false;
          })
      }

      this.smallScreen = this.screen.isSmallOrLess
    }

  getAddressString(job: JobDto) {
    return this.utils.generateJobAddressString(job.jobAddress)
  }

  openJob(jobNumber: string) {
    const companyId = this.authService.getCurrentCompany()?.id
    if (companyId)
      window.open(`${environment.truthEngineAppUrl}?coy=${companyId}&job=${jobNumber}`);
  }

  ngOnDestroy(): void {
    this.destroying.next(null);
    this.destroying.complete();
  }
}
