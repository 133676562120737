<mat-card class="dashboard-card">
  <mat-card-header>
    <mat-card-title [ngClass]="{'smaller-title' : screen.isXSmall}">
      {{cardInfo?.title}}
      <button mat-icon-button class="more-button"
              [matMenuTriggerFor]="menu" aria-label="Toggle menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button (click)="edit()" mat-menu-item>Edit</button>
        <button (click)="clone()" mat-menu-item>Clone</button>
        <mat-divider></mat-divider>
        <ng-container *ngIf="!screen.isSmallOrLess">
          <button *ngIf="isMedCard || isSmallCard" (click)="makeLarger()" mat-menu-item>
            Larger
          </button>
          <button *ngIf="isMedCard || isLargeCard" (click)="makeSmaller()" mat-menu-item>
            Smaller
          </button>
          <mat-divider></mat-divider>
        </ng-container>
        <button *ngIf="index > 0" (click)="move(true)" mat-menu-item>Move Left</button>
        <button *ngIf="!dashService.isLastCard(index)" (click)="move()" mat-menu-item>Move Right</button>
        <mat-divider></mat-divider>
        <button (click)="addRight()" mat-menu-item>Add Right</button>
      </mat-menu>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-card-content">
    <ng-container *ngIf="cardInfo?.chartTypeId === null">
      <img class="empty-img" src="assets/empty_card_(undraw_blank_canvas).svg">
      <button (click)="edit()" mat-flat-button color="primary" class="add-chart-btn">
        Click here to add a chart
      </button>
    </ng-container>
    <app-chart *ngIf="cardInfo?.chartTypeId !== null && isReady" [cardId]="cardInfo?.id"></app-chart>
  </mat-card-content>
</mat-card>
