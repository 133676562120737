import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DxTooltipModule } from 'devextreme-angular'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatIconModule } from '@angular/material/icon'
import { MatDialogModule } from '@angular/material/dialog'
import { HttpClientModule } from '@angular/common/http'
import { ToastrModule } from 'ngx-toastr'
import { SpinnerComponent } from './components/spinner/spinner.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { DecimalMaskPipe } from './decimal-place.pipe'
import { ConfirmComponent } from './components/confirm/confirm.component'
import { DatasourceNamePipe } from './data-source-name.pipe'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,

    ToastrModule.forRoot(),

    // DX
    DxTooltipModule,

    // MAT
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
  ],
  declarations: [ConfirmComponent, SpinnerComponent, DatasourceNamePipe, DecimalMaskPipe],
  exports: [
    ToastrModule,

    // DX
    DxTooltipModule,

    // MAT
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,

    // App
    SpinnerComponent,
    DatasourceNamePipe,
    DecimalMaskPipe,
  ],
  providers: [DatasourceNamePipe, DecimalMaskPipe],
})
export class SharedModule {}
