import { Component, NgModule, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';

import { UserPanelModule } from '../user-panel/user-panel.component';
import { UserDto } from 'src/app/types/dto/user.dto';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  user: UserDto | undefined;

  userMenuItems = [{
    text: 'Home',
    icon: 'home',
    onClick: () => {
      this.router.navigate(['/home']);
    }
  },
  {
    text: 'Sign Out',
    icon: 'runner',
    onClick: () => {
      this.auth.signOut();
    }
  }];

  constructor(
    private auth: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.auth.currentUser.subscribe(user => this.user = user);
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule
  ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule { }
