import { APP_ID, APP_NAME, B2C_AUTHORITY, B2C_AUTHORITY_DOMAIN, MAJOR_VERSION, PATCH_VERSION } from '../config/global-config';

export const environment = {
  production: true,
  local: false,
  applicationTitle: APP_NAME,
  clientName: APP_NAME,
  fullVersion: MAJOR_VERSION + PATCH_VERSION,
  majorVersion: MAJOR_VERSION,
  appId: APP_ID,

  applicationUrl: 'https://truthenginehub.azurewebsites.net/',
  apiUrl: 'https://api.truthengine.com.au/api/v1/',

  truthEngineAppUrl: 'https://truthengine.azurewebsites.net/',
  trackingAppUrl: 'https://truthenginetracking.azurewebsites.net/',
  purchaseOrdersAppUrl: 'https://truthenginepos.azurewebsites.net/',
  estimatingAppUrl: 'https://estimatingconfigapp.azurewebsites.net/',
  configAppUrl: 'https://truthengineconfiguration.azurewebsites.net/',
  adminAppUrl: 'https://truthengineadmin.azurewebsites.net/',

  authority: B2C_AUTHORITY,
  authorityDomain: B2C_AUTHORITY_DOMAIN,
  consentScopes: ['https://truthengine.onmicrosoft.com/FelixCoreApi/Access'],
  clientID: 'c1826393-c013-45c2-9282-9e7fdc3cf85e'
};
