import { Component, HostBinding, OnInit } from '@angular/core';
import { locale } from 'devextreme/localization';
import { ScreenService, AppInfoService } from './shared/services';
import { NotificationService } from './shared/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isIframe = false;

  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  constructor(
    private screen: ScreenService,
    private notiService: NotificationService,
    public appInfo: AppInfoService) {
    locale('AU');
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    const browser = window.navigator.userAgent.toLowerCase();
    if (browser.indexOf('firefox') > 0) {
      this.notiService.showWarning(
        'While this app functions on Firefox, Chrome is the officially supported browser. <br><br> If you encounter any issues please test on Chrome before raising it with support.',
        'Warning',
        { timeOut: 0, enableHtml: true, positionClass: 'toast-bottom-full-width' }
      );
    }
  }
}
